<template>
  <div
    class="unselect overflow-hidden"
    style="width:100%;height:100%;background:#F7F9FC;"
  >
    <v-app-bar app elevation="1" light clipped-right class="white pr-3">
      <img height="40" :src="`${baseUrl}/upload/login/logo.png`" />
      <v-spacer style="border-left:1px solid #eee" class="text-h6 ml-4 pl-4"
        >个人中心</v-spacer
      >
      <div class="text-center">
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" color="#444">
              {{ fullname || login_name }}
              <v-icon class="ml-1">mdi-account</v-icon>
            </v-btn>
          </template>
          <v-sheet class="overflow-hidden">
            <v-list dense expand nav>
              <v-list-item v-if="role === 'ordinary'" to="/user/launchpad">
                <v-list-item-title class="d-flex align-center">
                  <v-icon class="mr-2">mdi-cube-scan</v-icon>
                  应用面板
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="role === 'superAdmin'" to="/">
                <v-list-item-title class="d-flex align-center">
                  <v-icon class="mr-2">mdi-arrow-collapse-all</v-icon>
                  管理面板
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="#" @click="logout()">
                <v-list-item-title class="d-flex align-center">
                  <v-icon class="mr-2">mdi-logout</v-icon>
                  登出
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-menu>
      </div>
    </v-app-bar>
    <div
      class="d-flex justify-center"
      style="margin:90px auto;width:62%;height:80%;"
    >
      <!-- 左侧 -->
      <div style="width:280px" v-if="needBindWechat">
        <!-- 左上 -->
        <v-card
          elevation="0"
          class="pt-8 pb-4 d-flex flex-column align-center"
          style="border:1px solid #EAEBEE"
        >
          <v-sheet
            color="white"
            elevation="0"
            height="120"
            width="120"
            class="rounded-circle"
          >
            <v-img
              contain
              width="120"
              height="120"
              class="rounded-circle"
              :src="avatar_url ? avatar_url : require('@/assets/avatar.png')"
            ></v-img>
          </v-sheet>
          <v-card-title class="text-h6">
            {{ fullname || login_name }}
          </v-card-title>
        </v-card>
        <!-- 左下 -->
        <v-card
          elevation="0"
          class="mx-auto mt-6"
          width="280"
          style="border:1px solid #EAEBEE"
        >
          <v-list>
            <v-list-item-group v-model="curflow">
              <v-list-item
                v-for="item in tabItems"
                :key="item.name"
                @click="showCurInfo(item.name)"
                :class="{
                  'v-item--active v-list-item--active': curflow === item.name
                }"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
      <!-- 右侧 -->
      <div class="ml-6" style="flex:1 0;min-width:800px">
        <Component
          :is="sheet_flow"
          :curflow="curflow"
          :identity="identity"
          :externals="externals"
          @flowEvent="flowEvent($event)"
        />
      </div>
    </div>
    <Snackbar />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import Snackbar from "@/components/Snackbar";
import PersonInfo from "./tabComponent/PersonInfo";
import BindAccount from "./tabComponent/BindAccount";
import Binding from "./tabComponent/Binding";
import AuthLog from "./tabComponent/AuthLog";
import ApplicationLog from "./tabComponent/ApplicationLog";

const SHOW = {
  PersonInfo,
  BindAccount,
  Binding,
  AuthLog,
  ApplicationLog
};

export default {
  name: "UserHome",
  data() {
    return {
      needBindWechat: true,
      curflow: "PersonInfo",
      sheet_flow: "PersonInfo",
      avatar_url: null,
      login_name: "",
      fullname: "",
      has_password: null,
      identity: {},
      externals: [],
      role: null,
      baseUrl: null,
      device_keys: [],
      tabItems: [
        {
          icon: "mdi-account-circle-outline",
          name: "PersonInfo",
          text: "个人信息"
        },
        {
          icon: "mdi-arrow-decision",
          name: "BindAccount",
          text: "账号绑定"
        },
        {
          icon: "mdi-text-box-search-outline",
          name: "AuthLog",
          text: "登录日志"
        },
        {
          icon: "mdi-text-box-search-outline",
          name: "ApplicationLog",
          text: "应用访问日志"
        }
      ]
    };
  },
  created() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    let bindData = JSON.parse(localStorage.getItem("bindData"));
    let needBindWechat = localStorage.getItem("bindWechat");
    if (bindData || needBindWechat) {
      if (needBindWechat) {
        this.needBindWechat = false;
      }
      if ((bindData && bindData.identity_token) || needBindWechat) {
        this.sheet_flow = "Binding";
        this.curflow = "Binding";
      }
    }

    if (
      this.$store.state.identity.claims.role &&
      !this.$store.state.adminEntered
    ) {
      this.role = "superAdmin";
    } else if (
      this.$store.state.identity.claims.role &&
      location.port === "3088" &&
      this.$store.state.adminEntered
    ) {
      this.role = "superAdmin";
    } else if (
      this.$store.state.identity.claims.role &&
      location.port !== "3088" &&
      this.$store.state.adminEntered
    ) {
      this.role = null;
    } else {
      this.role = "ordinary";
    }

    this.getCurrent();
    bus.$off("newSet").$on("newSet", () => {
      this.getCurrent();
    });
  },
  methods: {
    flowEvent(name) {
      this.curflow = name;
      this.sheet_flow = SHOW[name];
    },
    showCurInfo(name) {
      this.curflow = name;
      this.sheet_flow = SHOW[name];
    },
    getCurrent() {
      this.$http
        .get(`api/current`)
        .delegateTo(api_request)
        .then(({ externals, identity, has_fido2_keys, has_password }) => {
          localStorage.setItem("has_fido2_keys", has_fido2_keys);
          this.has_password = has_password;
          this.identity = identity;
          this.externals = externals;
          let {
            name,
            claims: {
              emailaddress,
              login_name,
              preferred_username,
              mobilephone,
              nickname,
              created,
              source_name,
              fullname
            }
          } = identity;
          this.personInfo = identity.claims;
          this.preferred_username = preferred_username;

          this.name = name;
          this.fullname = fullname;
          this.emailaddress = emailaddress;
          this.login_name = login_name;
          this.mobilephone = mobilephone;
          this.nickname = nickname;
          // this.avatar_url = avatar_url;
          this.created = created;
          this.source_name = source_name;
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    logout() {
      this.$http
        .get(`api/logout`)
        .delegateTo(api_request)
        .then(() => {
          this.$snackbar.showMessage({
            content: `登出成功`,
            isError: false
          });
          setTimeout(async () => {
            let cellMode = localStorage.getItem("cellMode");
            let iconStatus = localStorage.getItem("iconStatus");
            let last_account = localStorage.getItem("last-account");
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem("cellMode", cellMode);
            localStorage.setItem("iconStatus", iconStatus);
            localStorage.setItem("last-account", last_account);
            await this.$store.commit("logout");
            this.$router.push("/login");
          }, 120);
        })
        .catch(({ code, message }) => {
          throw `登出失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  components: {
    Snackbar,
    PersonInfo,
    BindAccount,
    Binding,
    AuthLog
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-toolbar__content {
  padding: 4px 0 4px 12px;
}

/deep/ .v-list {
  padding: 0;
}

.menu {
  .v-icon {
    font-size: 30px;
  }
}
</style>
