<template>
  <div>
    <v-card elevation="0" style="border:1px solid #EAEBEE">
      <v-card-title class="text-body-1 mx-4" style="height:70px"
        >账号绑定</v-card-title
      >
      <v-divider></v-divider>
      <v-container class="px-4 py-4" style="min-height:500px">
        <div
          class="text-caption d-flex justify-space-between align-center"
          style="height:40px;line-height:40px"
        >
          <div class="ml-3 d-flex align-center">
            <v-icon>mdi-wechat</v-icon> 微信账号：
            <v-sheet
              color="white"
              elevation="0"
              height="24"
              width="24"
              class="rounded-circle mr-1"
              v-if="wechat_name"
            >
              <v-img
                contain
                width="24"
                height="24"
                class="rounded-circle"
                :src="headimgurl"
              ></v-img>
            </v-sheet>
            <span
              :style="{
                color: wechat_name ? '' : '#9195a3'
              }"
            >
              {{ wechat_name || "未绑定" }}
            </span>
          </div>
          <v-btn v-if="!exist" text color="#399CFF" @click="doBind">
            <v-icon color="#399CFF">mdi-link-variant</v-icon> 去绑定</v-btn
          >
          <v-btn v-if="exist" text @click="unBind">
            <v-icon color="#FB8B40">mdi-link-variant-off</v-icon>
            解除绑定
          </v-btn>
        </div>

        <div
          class="text-caption pb-4 d-flex justify-space-between align-center"
          style="height:40px;line-height:40px"
        >
          <div class="ml-3 d-flex align-center">
            <v-icon>mdi-account-key</v-icon> FIDO2认证绑定：
          </div>
          <v-btn text color="#399CFF" @click="toCreate" v-if="isSupport">
            <v-icon color="#399CFF">mdi-link-variant</v-icon>
            去绑定
          </v-btn>
          <v-btn v-else text color="#9195A3">
            <v-icon color="#9195A3">mdi-alert-circle-outline</v-icon
            >您当前设备不支持</v-btn
          >
        </div>

        <div
          class="px-4"
          style="position:relative;width:100%"
          v-if="allDevice_keys && allDevice_keys.length > 0"
        >
          <v-row
            v-for="item in allDevice_keys"
            :key="item.key_id"
            class="keyList"
            align="center"
          >
            <v-col cols="11" :title="item.key_id" class="d-flex">
              <div
                style="width:24px;height:24px;background-color:rgb(57, 156, 255)"
                class="mr-2"
              >
                <img
                  v-if="item.authenticator.icon"
                  :src="item.authenticator.icon"
                  style="width:100%;"
                />
                <v-icon v-else-if="item.name.includes('iPhone')">
                  mdi-apple
                </v-icon>
                <v-icon v-else>
                  mdi-key-outline
                </v-icon>
              </div>
              {{ item.name }} —— 绑定于{{
                item.created.substring(0, item.created.indexOf("T"))
              }}
            </v-col>
            <v-col cols="1">
              <v-btn
                icon
                elevation="0"
                color="red"
                @click.stop="deleteFido(item)"
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card>
    <ConfirmBox ref="confirm" />
    <ConfirmBoxFido ref="confirm1" @typeEvent="typeEvent($event)" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import { urlsafe_b64decode_array } from "@/util/jwes";
import { bufferEncode, supportFido } from "@/util/fido_util";
import ConfirmBox from "@/components/ConfirmBox";
import ConfirmBoxFido from "@/components/ConfirmBoxFido";

const platforms = ["platform", "cross-platform"];

export default {
  name: "BindAccount",
  props: ["externals", "identity"],
  data() {
    return {
      exist: false,
      external_id: null,
      type: null,
      wechatInfo: [],
      wechat_name: null,
      headimgurl: null,
      allDevice_keys: [],
      typeId: 0,
      platforms,
      isSupport: false
    };
  },
  created() {
    this.getExternals();
    this.refreshDeviceList();
    this.isSupport = supportFido();
  },
  methods: {
    typeEvent(id) {
      this.typeId = id;
    },
    // 去绑定fido
    toCreate() {
      this.$refs.confirm1
        .showConfirm({
          callback: () => {
            return this.$http
              .post(`api/fido2/register/challenge`)
              .delegateTo(api_request)
              .then(({ challenge }) => {
                let {
                  claims: { login_name, nickname }
                } = this.identity;

                let publicKey = {
                  attestation: "direct",
                  challenge: urlsafe_b64decode_array(challenge),
                  rp: {
                    name: this.$store.state.system_name
                  },
                  pubKeyCredParams: [
                    { type: "public-key", alg: -7 },
                    { type: "public-key", alg: -257 }
                  ],
                  timeout: 60000,
                  user: {
                    displayName: login_name || nickname,
                    id: urlsafe_b64decode_array(this.identity.name),
                    name: login_name || nickname
                  },
                  authenticatorSelection: {
                    authenticatorAttachment: platforms[this.typeId],
                    requireResidentKey: true,
                    userVerification: "required"
                  }
                };

                navigator.credentials
                  .create({ publicKey })
                  .then(newCredential => {
                    this.registerNewCredential(
                      newCredential,
                      platforms[this.typeId]
                    );
                  })
                  .catch(() => {
                    this.$refs.confirm1.loading = false;
                    throw `注册失败：操作超时或已被取消`;
                  })
                  .delegateTo(this.$snackbar.delegateError);
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    refreshDeviceList() {
      return this.$http
        .get(`api/fido2/keys`)
        .delegateTo(api_request)
        .then(data => {
          this.allDevice_keys = data.fido2_keys;
          if (
            data.fido2_keys &&
            (data.fido2_keys.length === 0 || data.fido2_keys.length === 1)
          ) {
            bus.$emit("newSet");
          }
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    deleteFido(deviceInfo) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除此密钥吗？`,
          contentText2: `密钥名称：${deviceInfo.name}`,
          contentText3: `密钥ID：${deviceInfo.key_id}`,
          callback: () => {
            return this.$http
              .delete(`api/fido2/key/${deviceInfo.key_id}`)
              .delegateTo(api_request)
              .then(() => {
                this.refreshDeviceList();
                return "设备key已成功删除";
              })
              .catch(({ code, message }) => {
                throw `设备key删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    unBind() {
      this.$refs.confirm
        .showConfirm({
          contentText: `确认解绑微信账号：${this.wechat_name}吗？一旦解绑，将不能使用此微信账号扫码登录`,
          callback: () => {
            return this.$http
              .post(`api/auth/unbind_account`, {
                external_id: this.external_id,
                type: this.type
              })
              .delegateTo(api_request)
              .then(() => {
                this.isCloseBind = true;
                sessionStorage.clear();
                localStorage.removeItem("confirmBind");
                localStorage.removeItem("bindInfo");
                bus.$emit("newSet");
                return "解绑成功";
              })
              .catch(({ code, message }) => {
                throw `解绑失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
                  "api." + typeof message === "string"
                    ? message
                    : JSON.stringify(message)
                )}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    doBind() {
      this.$emit("flowEvent", "Binding");
    },
    getExternals() {
      if (this.externals.length > 0) {
        this.exist = this.externals.some(
          item => item.type === "WECHAT" || item.type === "WECHAT_RZKC"
        );
        this.wechatInfo = this.externals.filter(
          item => item.type === "WECHAT" || item.type === "WECHAT_RZKC"
        );

        if (this.wechatInfo.length > 0) {
          let {
            id,
            type,
            claims: { nickname: wechat_name, headimgurl }
          } = this.wechatInfo[0];
          this.external_id = id;
          this.type = type;
          this.headimgurl = headimgurl;
          this.wechat_name = wechat_name;
        } else {
          this.wechat_name = "";
        }
      } else {
        this.wechat_name = null;
        this.exist = false;
      }
    },
    // This should be used to verify the auth data with the server
    registerNewCredential(newCredential, platformType) {
      let attestationObject = new Uint8Array(
        newCredential.response.attestationObject
      );

      let clientDataJSON = new Uint8Array(
        newCredential.response.clientDataJSON
      );

      let payload = {
        attestationObject: bufferEncode(attestationObject),
        clientDataJSON: bufferEncode(clientDataJSON),
        authenticator_attachment: platformType
      };

      return this.$http
        .post(`api/fido2/register`, payload)
        .delegateTo(api_request)
        .then(() => {
          this.refreshDeviceList();
          this.$refs.confirm1.show = false;
          this.$refs.confirm1.loading = false;
          return "注册成功";
        })
        .catch(({ code, message }) => {
          this.$refs.confirm1.show = false;
          this.$refs.confirm1.loading = false;
          throw `注册失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegate);
    }
  },
  watch: {
    externals() {
      this.getExternals();
    }
  },
  components: {
    ConfirmBox,
    ConfirmBoxFido
  }
};
</script>

<style lang="less" scoped>
.keyList {
  &:hover {
    background-color: #e6e6e6;
    border-radius: 4px;
  }
}
</style>
