<template>
  <div>
    <v-card elevation="0" class="pb-8" style="border:1px solid #EAEBEE">
      <div class="px-4 d-flex align-center justify-space-between">
        <v-card-title class="text-body-1">
          基本信息
        </v-card-title>
        <v-card-title>
          <v-btn
            depressed
            color="primary"
            class="mr-6"
            @click="editPersonInfo('ChangePassword')"
          >
            <v-icon>mdi-lock-reset</v-icon>修改密码
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="editPersonInfo('ChangePersonInfo')"
          >
            <v-icon>mdi-square-edit-outline</v-icon>修改个人信息
          </v-btn>
        </v-card-title>
      </div>

      <v-divider></v-divider>
      <v-container class="px-8">
        <v-row
          class="text-body-2 d-flex align-center justify-space-between pt-8"
        >
          <v-col cols="6">
            <span>ID</span>
          </v-col>
          <v-col cols="6">
            <span>用户名</span>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-space-between">
          <v-col cols="6">
            <v-text-field
              class="text-body-2"
              v-model="name"
              filled
              hide-details
              disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              class="text-body-2"
              v-model="login_name"
              filled
              hide-details
              disabled="disabled"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          class="text-body-2 d-flex align-center justify-space-between pt-8"
        >
          <v-col cols="6">
            <span>姓名</span>
          </v-col>
          <v-col cols="6">
            <span>昵称</span>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-space-between">
          <v-col cols="6">
            <v-text-field
              class="text-body-2"
              v-model="fullname"
              filled
              hide-details
              disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              class="text-body-2"
              v-model="nickname"
              filled
              hide-details
              disabled="disabled"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          class="text-body-2 d-flex align-center justify-space-between pt-8"
        >
          <v-col cols="6">
            <span>注册日</span>
          </v-col>
          <v-col cols="6">
            <span>用户来源</span>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-space-between">
          <v-col cols="6">
            <v-text-field
              class="text-body-2"
              v-model="created"
              filled
              hide-details
              disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              class="text-body-2"
              v-model="source_name"
              filled
              hide-details
              disabled="disabled"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          class="text-body-2 d-flex align-center justify-space-between pt-8"
        >
          <v-col cols="6">
            <span>邮箱</span>
          </v-col>
          <v-col cols="6">
            <span>手机号</span>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center justify-space-between">
          <v-col cols="6" class="py-0">
            <v-text-field
              class="text-body-2"
              v-model="emailaddress"
              filled
              hide-details
              disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              class="text-body-2"
              v-model="mobilephone"
              filled
              hide-details
              disabled="disabled"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <Snackbar />
    <Component
      :is="sheet_editor"
      :show="drawer_status"
      is_modify="edit"
      device="pc"
      type="edit"
      :personInfo="personInfo"
    />
  </div>
</template>

<script>
import Snackbar from "@/components/Snackbar";
import ChangePassword from "../drawer/ChangePassword";
import ChangePersonInfo from "../drawer/ChangePersonInfo";

const EDITORS = {
  ChangePassword,
  ChangePersonInfo
};

export default {
  name: "PersonInfo",
  props: ["identity"],
  data() {
    return {
      drawer_status: [false, false],
      sheet_editor: null,
      personInfo: {},
      emailaddress: null,
      login_name: null,
      preferred_username: null,
      mobilephone: null,
      nickname: null,
      avatar_url: null,
      created: null,
      source_name: null,
      fullname: null,
      name: null
    };
  },
  created() {
    this.getIdentity();
  },
  methods: {
    editPersonInfo(type) {
      this.sheet_editor = EDITORS[type];
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    getIdentity() {
      let { name, claims } = this.identity;
      this.personInfo = claims;
      this.name = name;
      if (claims) {
        this.emailaddress = claims.emailaddress || "-";
        this.login_name = claims.login_name || "-";
        this.preferred_username = claims.preferred_username || "-";
        this.mobilephone = claims.mobilephone || "-";
        this.nickname = claims.nickname || "-";
        this.avatar_url = claims.avatar_url || "-";
        this.fullname = claims.fullname || "-";
        this.source_name = claims.source_name || "-";
        this.created = claims.created || "-";
      }
    }
  },
  watch: {
    identity() {
      this.getIdentity();
    }
  },
  components: {
    Snackbar,
    ChangePassword,
    ChangePersonInfo
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-input__slot {
  min-height: 45px !important;
}

/deep/ .col-6 {
  padding: 0 12px;
}

/deep/ .v-input--is-disabled input {
  color: rgba(0, 0, 0, 0.9);
}
</style>
