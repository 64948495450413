<template>
  <div class="unselect overflow-hidden" style="width:100%;height:100%;">
    <v-app-bar app elevation="1" light clipped-right class="white">
      <img height="30" :src="`${baseUrl}/upload/login/logo.png`" />
      <v-spacer style="border-left:1px solid #eee" class="ml-4 pl-4"
        >个人中心</v-spacer
      >
      <div>
        <v-menu :nudge-width="140" :nudge-bottom="28">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-app-bar-nav-icon class="menu"></v-app-bar-nav-icon>
            </v-btn>
          </template>
          <v-sheet class="overflow-hidden">
            <v-list dense expand nav>
              <v-list-item v-if="applications.length > 0" to="/user/launchpad">
                <v-list-item-title class="d-flex align-center justify-center">
                  <v-icon class="mr-3">mdi-cube-scan</v-icon>
                  应用面板
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="logout()">
                <v-list-item-title class="d-flex align-center justify-center">
                  <v-icon class="mr-2">mdi-logout</v-icon>
                  退出登录
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-menu>
      </div>
    </v-app-bar>

    <!-- 第一页登录操作页 -->
    <div
      class="white lighten-2 mt-16 px-6 d-flex flex-column flex-lg-grow-0 flex-md-grow-0"
      style="width:100%;height: 100%;max-width:400px;margin: 0 auto"
      v-if="firstpage"
    >
      <div class="d-flex flex-row align-center mt-4 mb-3">
        <v-sheet
          color="white"
          elevation="2"
          height="60"
          width="60"
          class="rounded-circle"
        >
          <v-img
            contain
            width="60"
            height="60"
            class="rounded-circle"
            :src="avatar_url ? avatar_url : require('@/assets/avatar.png')"
          ></v-img>
        </v-sheet>
        <div class="text-h6 text-center ml-6 text--black">
          <div>{{ login_name }}</div>
        </div>
      </div>

      <div
        class="d-inline-flex flex-row justify-space-between mt-4"
        style="border-bottom: 1px dotted grey;width:100%"
      >
        <div class="text-lg-h6 text-md-h6 text-sm-h6 text-xs-h6">
          用户资料
        </div>
        <div
          class="text-body-2 align-self-end"
          @click.stop="show_sheet('ChangePersonInfo')"
        >
          修改信息
        </div>
      </div>
      <div class="white">
        <div
          class="text-caption d-flex flex-row justify-space-between px-2"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px"
        >
          <div>ID</div>
          <div
            :style="{
              color: name ? '' : '#9195a3'
            }"
          >
            {{ name ? name : "未填写" }}
          </div>
        </div>
        <div
          class="text-caption d-flex flex-row justify-space-between px-2"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px"
        >
          <div>用户名</div>
          <div
            :style="{
              color: login_name ? '' : '#9195a3'
            }"
          >
            {{ login_name ? login_name : "未填写" }}
          </div>
        </div>
        <div
          class="text-caption d-flex flex-row justify-space-between px-2"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px"
        >
          <div>邮箱</div>
          <div
            :style="{
              color: emailaddress ? '' : '#9195a3'
            }"
          >
            {{ emailaddress ? emailaddress : "未填写" }}
          </div>
        </div>
        <div
          class="text-caption d-flex flex-row justify-space-between px-2"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px"
        >
          <div>手机号</div>
          <div
            :style="{
              color: mobilephone ? '' : '#9195a3'
            }"
          >
            {{ mobilephone ? mobilephone : "未填写" }}
          </div>
        </div>
        <div
          class="text-caption d-flex flex-row justify-space-between px-2"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px"
        >
          <div>姓名</div>
          <div
            :style="{
              color: fullname ? '' : '#9195a3'
            }"
          >
            {{ fullname ? fullname : "未填写" }}
          </div>
        </div>
        <div
          class="text-caption d-flex flex-row justify-space-between px-2"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px"
        >
          <div>昵称</div>
          <div
            :style="{
              color: nickname ? '' : '#9195a3'
            }"
          >
            {{ nickname ? nickname : "未填写" }}
          </div>
        </div>
        <div
          class="text-caption d-flex flex-row justify-space-between px-2"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px"
        >
          <div>注册日</div>
          <div
            :style="{
              color: created ? '' : '#9195a3'
            }"
          >
            {{ created ? created : "未知" }}
          </div>
        </div>
        <div
          class="text-caption d-flex flex-row justify-space-between px-2"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px"
        >
          <div>用户来源</div>
          <div
            :style="{
              color: source_name ? '' : '#9195a3'
            }"
          >
            {{ source_name ? source_name : "未知" }}
          </div>
        </div>
      </div>

      <div
        class="d-inline-flex flex-row justify-space-between mt-4"
        style="border-bottom: 1px dotted grey;"
      >
        <div class="text-lg-h6 text-md-h6 text-sm-h6 text-xs-h6">
          账号绑定
        </div>
        <div
          class="text-body-2 align-self-end"
          @click.stop="show_sheet('ChangePassword')"
        >
          修改密码
        </div>
      </div>
      <div class="white">
        <div
          class="text-caption d-flex flex-row justify-space-between align-center"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px"
        >
          <div>
            <v-btn icon> <v-icon>mdi-account</v-icon> </v-btn>微信账号
          </div>
          <v-bottom-sheet
            v-if="exist"
            v-model="controlBind"
            inset
            hide-overlay
            max-width="400"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ wechat_name }}
                <v-btn icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </template>
            <v-sheet class="text-center unselect" style="border-radius:6px">
              <div class="d-flex flex-column align-center pt-6">
                <v-sheet
                  color="white"
                  elevation="2"
                  height="60"
                  width="60"
                  class="rounded-circle"
                  style="border-radius:6px"
                >
                  <v-img
                    contain
                    width="60"
                    height="60"
                    class="rounded-circle"
                    :src="
                      headimgurl ? headimgurl : require('@/assets/avatar.png')
                    "
                  ></v-img>
                </v-sheet>
                <div
                  class="black--text text-body2 d-flex flex-column align-center mt-2"
                >
                  <div>{{ wechat_name }}</div>
                </div>
              </div>
              <v-btn
                :style="{ background: `${highlightColor}` }"
                min-width="300"
                min-height="40"
                class="white--text my-6"
                @click="unBind"
              >
                解除微信绑定
              </v-btn>
            </v-sheet>
          </v-bottom-sheet>
          <div v-if="!exist" @click="toBind">
            <span class="red--text">去绑定</span>
            <v-btn icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
        <div
          class="text-caption d-flex flex-row justify-space-between align-center"
          style="border-bottom: 1px solid #BDBDBD;height:48px;line-height:48px"
        >
          <div>
            <v-btn icon> <v-icon>mdi-cellphone-basic</v-icon> </v-btn>手机号
          </div>
          <div
            :style="{
              color: mobilephone ? '' : '#9195a3'
            }"
          >
            {{ mobilephone ? mobilephone : "未填写" }}
            <v-btn icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- 第三页确认绑定 -->
    <div
      class="white lighten-2 pt-9 pb-9 d-inline-flex flex-column flex-lg-grow-0 flex-md-grow-0 overflow-hidden"
      :style="{
        width: '100%',
        height: '100vh',
        maxWidth: '400px',
        margin: '0 auto'
      }"
      v-if="confirmBind"
    >
      <!-- 微信账号绑定 -->
      <div
        class="white lighten-2 d-inline-flex flex-column justify-space-between pt-9 pb-12 overflow-hidden"
        style="width: 100%; height: 100vh;"
      >
        <div class="d-flex flex-column align-center">
          <div
            class="mb-16 text-lg-h6 text-md-h6 text-sm-h6 text-xs-h6 text-center"
            :style="{ color: `${highlightColor}` }"
          >
            微信账号绑定
          </div>
          <v-sheet
            color="white"
            elevation="2"
            height="120"
            width="120"
            class="rounded-circle"
          >
            <v-img
              contain
              width="120"
              height="120"
              class="rounded-circle"
              :src="headimgurl ? headimgurl : require('@/assets/avatar.png')"
            ></v-img>
          </v-sheet>
          <div
            class="text-lg-h6 text-md-h6 text-sm-h6 text-xs-h6 mt-6 text-center"
            :style="{ color: `${highlightColor}` }"
          >
            {{ fullname || nickname || login_name }}
          </div>
          <div
            class="mt-12 text-lg-h6 text-md-h6 text-sm-h6 text-xs-h6 text-center"
            :style="{ color: `${highlightColor}` }"
          >
            <div>你即将绑定此微信账号</div>
            <div>后续可以使用此微信账号登录</div>
          </div>
        </div>

        <div class="text-body-2 d-flex flex-column align-center">
          <v-btn
            @click="doBind"
            :style="{ background: `${highlightColor}` }"
            min-width="300"
            min-height="40"
            class="white--text"
          >
            绑定
          </v-btn>
          <v-btn
            @click="cancelBind"
            min-width="300"
            min-height="40"
            class="white--text grey lighten-1 mt-6"
            style="color:#707B9A;font-weight:600"
          >
            取消绑定
          </v-btn>
        </div>
      </div>
    </div>

    <ConfirmBox ref="confirm" />
    <Snackbar />
    <Component
      :is="sheet_editor"
      :show="drawer_status"
      is_modify="edit"
      device="mobile"
      type="edit"
      :personInfo="personInfo"
      :has_password="has_password"
    />
  </div>
</template>

<script>
import Cookie from "js-cookie";
import Snackbar from "@/components/Snackbar";
import bus from "@/util/eventBus";
import ChangePassword from "./drawer/ChangePassword";
import ChangePersonInfo from "./drawer/ChangePersonInfo";
import { api_request } from "@/util/network";
import { transformUint8ArrayToBase64 } from "@/util/util";
import ConfirmBox from "@/components/ConfirmBox";

const EDITORS = {
  ChangePassword,
  ChangePersonInfo
};

export default {
  name: "UserHome",
  inject: ["reload"],
  data() {
    return {
      drawer_status: [false, false],
      sheet_editor: null,
      firstpage: true,
      confirmBind: false,
      controlBind: false,
      isCloseBind: false,
      externals: [],
      personInfo: {},
      exist: false,
      headimgurl: null,
      wechat_name: null,
      avatar_url: null,
      login_name: "",
      preferred_username: "",
      nickname: null,
      fullname: null,
      name: null,
      emailaddress: null,
      mobilephone: null,
      source_name: null,
      created: null,
      external_id: null,
      type: null,
      validTimer: 60,
      isValid: false,
      scanSuccess: false,
      applications: [],
      role: null,
      has_password: null,
      baseUrl: null,
      highlightColor: "#FF0000"
    };
  },
  async created() {
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    let bindInfo = JSON.parse(sessionStorage.getItem("bindInfo"));
    let finishData = JSON.parse(localStorage.getItem("finishData"));
    let rebinding = sessionStorage.getItem("rebinding");
    let needBind = sessionStorage.getItem("needBind");
    let bindInfoData = bindInfo || finishData;
    if ((bindInfoData && rebinding) || needBind) {
      this.confirmBindPage();
    } else {
      this.fetchStyle();
      this.getCurrent();
    }

    if (!this.$store.state.identity.claims.role) {
      this.fetchApplications();
    }

    bus.$off("newSet").$on("newSet", () => {
      this.getCurrent();
    });
  },
  methods: {
    show_sheet(type) {
      this.sheet_editor = EDITORS[type];
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    confirmBindPage() {
      let finishData = JSON.parse(localStorage.getItem("finishData"));
      let rebindInfo = JSON.parse(sessionStorage.getItem("bindInfo"));
      let rebinding = sessionStorage.getItem("rebinding");
      if (rebinding) {
        this.confirmBind = true;
        this.firstpage = false;
        let { headimgurl, nickname } = rebindInfo;
        this.headimgurl = headimgurl;
        this.nickname = nickname;
      } else {
        this.confirmBind = true;
        this.firstpage = false;
        let { headimgurl, nickname } = finishData;
        this.headimgurl = headimgurl;
        this.nickname = nickname;
      }
    },
    getCurrent() {
      this.$http
        .get(`api/current`)
        .delegateTo(api_request)
        .then(({ externals, identity, has_fido2_keys, has_password }) => {
          localStorage.setItem("has_fido2_keys", has_fido2_keys);
          this.has_password = has_password;
          let {
            name,
            claims: {
              emailaddress,
              login_name,
              preferred_username,
              mobilephone,
              nickname,
              avatar_url,
              created,
              source_name,
              fullname
            }
          } = identity;
          this.personInfo = identity.claims;
          this.preferred_username = preferred_username;
          if (externals.length > 0) {
            this.externals = externals;
            this.exist = this.externals.some(
              item => item.type === "WECHAT" || item.type === "WECHAT_RZKC"
            );

            this.wechatInfo = this.externals.find(
              item => item.type === "WECHAT" || item.type === "WECHAT_RZKC"
            );

            if (this.wechatInfo) {
              let {
                id,
                type,
                claims: { headimgurl, nickname: wechat_name }
              } = this.wechatInfo;
              this.external_id = id;
              this.type = type;
              this.headimgurl = headimgurl;
              this.wechat_name = wechat_name;
            }
          }
          this.name = name;
          this.fullname = fullname;
          this.emailaddress = emailaddress;
          this.login_name = login_name;
          this.mobilephone = mobilephone;
          this.nickname = nickname;
          this.avatar_url = avatar_url;
          this.created = created;
          this.source_name = source_name;
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    unBind() {
      this.controlBind = !this.controlBind;
      if (this.external_id && this.type) {
        this.$http
          .post(`api/auth/unbind_account`, {
            external_id: this.external_id,
            type: this.type
          })
          .delegateTo(api_request)
          .then(() => {
            this.isCloseBind = true;
            this.exist = false;
            sessionStorage.clear();
            localStorage.removeItem("confirmBind");
            localStorage.removeItem("bindInfo");
            return "解绑成功";
          })
          .catch(({ code, message }) => {
            throw `解绑失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    doBind() {
      let finishData = JSON.parse(localStorage.getItem("finishData"));
      let rebindInfo = JSON.parse(sessionStorage.getItem("bindInfo"));
      let rebinding = sessionStorage.getItem("rebinding");
      let payload;
      if (rebinding) {
        let { identity_token, session_id } = rebindInfo;
        payload = {
          session_id,
          identity_token
        };
      } else {
        let { identity_token, session_id } = finishData;
        payload = {
          session_id,
          identity_token
        };
      }

      return this.$http
        .post(`api/auth/bind_account`, payload)
        .delegateTo(api_request)
        .then(() => {
          this.$snackbar.showMessage({
            content: `绑定成功`,
            isError: false
          });

          setTimeout(() => {
            this.firstpage = true;
            this.confirmBind = false;
            sessionStorage.clear();
            this.getCurrent();
          }, 10);
          // return "绑定成功";
        })
        .catch(({ code, message }) => {
          sessionStorage.removeItem("session_id");
          this.getCurrent();

          throw `绑定失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    async cancelBind() {
      this.firstpage = true;
      this.confirmBind = false;
      sessionStorage.clear();
      localStorage.removeItem("bindInfo");
      localStorage.removeItem("confirmBind");
      this.getCurrent();
    },
    async toBind() {
      let ua = window.navigator.userAgent.toLowerCase();
      if (
        ua.match(/micromessenger/i) == "micromessenger" &&
        ua.match(/wxwork/i) == "wxwork"
      ) {
        this.$snackbar.showMessage({
          content: "请在微信中绑定",
          isError: true
        });
      } else if (ua.match(/micromessenger/i) == "micromessenger") {
        Cookie.remove("session_id");
        let session = await this.randomSessionId();
        let millisecond = new Date().getTime();
        let expiresTime = new Date(millisecond + 60 * 1000 * 1);
        Cookie.set("session_id", session, {
          expires: expiresTime
        });

        sessionStorage.setItem("needRebind", true);

        this.$router.push({
          path: "/wechat/authorize",
          query: {
            session: session
          }
        });
      } else {
        this.$snackbar.showMessage({
          content: "请在微信中绑定",
          isError: true
        });
      }
    },
    goback() {
      this.firstpage = true;
      this.confirmBind = false;
      sessionStorage.removeItem("session_id");
      localStorage.removeItem("bindInfo");
      this.reload();
    },
    consumeSession() {
      return new Promise(resolve => {
        return this.$http
          .post(`api/auth/consume_session`, {
            session_id: sessionStorage.getItem("session_id")
          })
          .delegateTo(api_request)
          .then(data => {
            resolve(data);
          })
          .catch(({ code, message }) => {
            throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .catch(() => {
            return;
          });
      });
    },
    valid() {
      let timer = setInterval(async () => {
        if (this.validTimer > 0) {
          this.validTimer = this.validTimer - 1;
          if (this.validTimer % 3 === 0) {
            let res = await this.consumeSession();
            if (res.identity_token) {
              clearInterval(timer);
              setTimeout(() => {
                this.confirmBind = true;
                this.firstpage = false;
                this.scanPage = false;
                localStorage.setItem("confirmBind", true);
                let {
                  claims: { headimgurl, nickname }
                } = res.identity;
                this.headimgurl = headimgurl;
                this.nickname = nickname;
                localStorage.setItem(
                  "bindInfo",
                  JSON.stringify({
                    token: res.identity_token,
                    headimgurl,
                    nickname
                  })
                );
              }, 2000);
            }
          }
        } else {
          clearInterval(timer);
          this.isValid = true;
          sessionStorage.removeItem("session_id");
          this.reload();
        }
      }, 1000);
    },
    logout() {
      this.$http
        .get(`api/logout`)
        .delegateTo(api_request)
        .then(() => {
          this.$snackbar.showMessage({
            content: `登出成功`,
            isError: false
          });
          setTimeout(async () => {
            let last_account = localStorage.getItem("last-account");
            sessionStorage.setItem("logoutStatus", true);
            localStorage.clear();
            localStorage.setItem("last-account", last_account);
            await this.$store.commit("logout");
            this.$router.push("/login");
          }, 120);
        })
        .catch(({ code, message }) => {
          throw `登出失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    randomSessionId() {
      let ua = new Uint8Array(20);
      new DataView(ua.buffer).setUint32(0, Math.floor(+new Date() / 1000));
      let crypto = window.crypto || window.msCrypto;
      if (crypto) {
        crypto.getRandomValues(ua.subarray(4, 20));
      }

      return (
        "1." +
        transformUint8ArrayToBase64(ua)
          .replaceAll("+", "-")
          .replaceAll("/", "_")
      );
    },
    fetchApplications() {
      this.$http
        .get(`api/applications`)
        .delegateTo(api_request)
        .then(data => {
          this.applications = data.filter(item => item.type !== "WebEXP");
        })
        .catch(({ code, message }) => {
          throw `获取应用列表数据失败：${this.$t(
            "api." + code
          )}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchStyle() {
      return this.$http
        .get(`api/settings/login`)
        .delegateTo(api_request)
        .then(config => {
          let {
            loginFrame: { loginHighLight }
          } = config;

          this.highlightColor = loginHighLight;
        })
        .catch(({ code, message }) => {
          throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  components: {
    Snackbar,
    ChangePassword,
    ChangePersonInfo,
    ConfirmBox
  }
};
</script>
<style lang="less" scoped>
/deep/.v-btn:not(.v-btn--round).v-size--default {
  height: 0;
  min-width: 0;
  padding: 0;
}
/deep/ .v-toolbar__content {
  padding: 4px 0 4px 12px;
}

/deep/ .v-menu__content {
  right: 0;
}

.menu {
  .v-icon {
    font-size: 30px;
  }
}

.v-menu__content--fixed {
  left: auto !important;
  right: 0 !important;
}
</style>
