<template>
  <v-navigation-drawer
    app
    temporary
    :width="device === 'pc' ? '30vw' : '80vw'"
    right
    v-model="show[0]"
    class="directory-tree"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-lg-h6 text-md-h6 text-sm-h6 text-xs-h6">
          修改个人信息
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-container :class="device === 'pc' ? 'pa-4' : 'pa-0'">
      <v-form
        :class="device === 'pc' ? 'pa-4' : 'pa-0'"
        v-model="valid"
        ref="form"
      >
        <v-row style="border-bottom: none !important;" class="align-center">
          <v-col cols="3">
            <v-subheader
              ><span class="red--text">*</span>
              {{ $t("account.label.fullname") }}</v-subheader
            >
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="fullname"
              :placeholder="$t('account.placeholder.fullname')"
              :rules="rules.notNullRules"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="align-center">
          <v-col cols="3">
            <v-subheader> {{ $t("account.label.nickname") }}</v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="nickname"
              :placeholder="$t('account.placeholder.nickname')"
              persistent-hint
              style="margin-left:6px;"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="align-center">
          <v-col cols="3">
            <v-subheader> {{ $t("account.label.email") }}</v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="emailaddress"
              :placeholder="$t('account.placeholder.email')"
              persistent-hint
              style="margin-left:6px;"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="align-center">
          <v-col cols="3">
            <v-subheader> {{ $t("account.label.mobile") }}</v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="mobilephone"
              :placeholder="$t('account.placeholder.mobile')"
              persistent-hint
              style="margin-left:6px;"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          elevation="4"
          medium
          color="primary"
          @click.stop="changePersonInfo()"
          >确认修改</v-btn
        >
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "ChangePassword",
  props: ["show", "is_modify", "personInfo", "device"],
  data() {
    let isTrueEmail = value => {
      let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      return reg.test(value);
    };

    let isTruePhone = value => {
      let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      return reg.test(value);
    };
    return {
      valid: true,
      fullname: null,
      nickname: null,
      emailaddress: null,
      mobilephone: null,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isEmail: [v => isTrueEmail(v) || "不符合邮箱规则"],
        isPhone: [v => isTruePhone(v) || "不符合手机号规则"]
      }
    };
  },
  created() {
    this.getCurData();
  },
  methods: {
    getCurData() {
      let {
        login_name,
        fullname,
        nickname,
        emailaddress,
        mobilephone
      } = this.personInfo;
      this.login_name = login_name;
      this.fullname = fullname;
      this.nickname = nickname;
      this.emailaddress = emailaddress;
      this.mobilephone = mobilephone;
    },
    changePersonInfo() {
      if (this.$refs.form.validate()) {
        let payload = {
          fullname: this.fullname,
          nickname: this.nickname,
          emailaddress: this.emailaddress,
          mobilephone: this.mobilephone
        };
        this.$http
          .post(`api/account/modify_profile`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            bus.$emit("newSet");
            return `已成功修改账户 ${this.login_name ||
              this.fullname ||
              this.nickname ||
              ""} ) 的信息。`;
          })
          .catch(({ code, message }) => {
            throw `密码修改失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    }
  }
};
</script>
