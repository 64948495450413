<template>
  <v-navigation-drawer
    app
    temporary
    :width="device === 'pc' ? '30vw' : '80vw'"
    right
    v-model="show[0]"
    class="directory-tree"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-lg-h6 text-md-h6 text-sm-h6 text-xs-h6">
          修改密码
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-container :class="device === 'pc' ? 'pa-4' : 'pa-0'">
      <v-form
        :class="device === 'pc' ? 'pa-4' : 'pa-0'"
        v-model="valid"
        ref="form"
      >
        <v-row
          style="border-bottom: none !important;"
          v-if="login_name"
          class="align-center"
        >
          <v-col cols="3">
            <v-subheader> {{ $t("account.label.login_name") }}</v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="login_name"
              :placeholder="$t('account.placeholder.login_name')"
              readonly="readonly"
              disabled="disabled"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          style="border-bottom: none !important;"
          v-if="!login_name"
          class="align-center"
        >
          <v-col cols="3">
            <v-subheader>
              {{ $t("account.label.preferred_username") }}</v-subheader
            >
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="preferred_username"
              :placeholder="$t('account.placeholder.preferred_username')"
              persistent-hint
              :hint="$t('account.hint.preferred_username')"
              style="margin-left:6px;"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense v-if="has_password" class="align-center">
          <v-col cols="3">
            <v-subheader
              ><span class="red--text">*</span>
              {{ $t("account.label.old_password") }}</v-subheader
            >
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="old_password"
              :placeholder="$t('account.placeholder.old_password')"
              :hint="$t('account.hint.old_password')"
              persistent-hint
              type="password"
              required
              :rules="rules.ispassword"
              style="margin-left:6px;"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="align-center">
          <v-col cols="3">
            <v-subheader
              ><span class="red--text">*</span>
              {{ $t("account.label.new_password") }}</v-subheader
            >
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="new_password"
              :placeholder="$t('account.placeholder.new_password')"
              :hint="pwdhint"
              persistent-hint
              type="password"
              required
              :rules="rules.ispassword"
              style="margin-left:6px;"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="align-center">
          <v-col cols="3">
            <v-subheader
              ><span class="red--text">*</span>
              {{ $t("account.label.confirm_password") }}</v-subheader
            >
          </v-col>
          <v-col cols="9">
            <v-text-field
              rows="1"
              v-model="confirm_password"
              :placeholder="$t('account.placeholder.confirm_password')"
              :hint="$t('account.hint.confirm_password')"
              persistent-hint
              type="password"
              required
              :rules="rules.isconfirm"
              style="margin-left:6px;"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn
          elevation="4"
          medium
          color="primary"
          @click.stop="changePassword()"
          >确认修改</v-btn
        >
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

const pwdHints = {
  STRONG:
    "包含大写字母、小写字母、数字、特殊字符至少三种以上，长度大于8位，小于20位",
  MEDIUM: "包含小写字母、数字、特殊字符至少两种以上，长度大于8位，小于20位",
  SIMPLE: "必须包含小写字母、数字、特殊字符各一位，长度大于6位，小于20位"
};

export default {
  name: "ChangePassword",
  props: ["show", "is_modify", "personInfo", "device", "has_password"],
  data() {
    let isTruePassword = value => {
      let regex;
      if (this.pwdStrength === "STRONG") {
        regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}$/;
      } else if (this.pwdStrength === "MEDIUM") {
        regex = /(?!^[0-9]+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{8,20}$/;
      } else {
        regex = /^(?![^a-zA-Z]+$)(?!\D+$)(?![a-zA-Z0-9]+$).{8,}$/;
      }

      return value && regex.test(value);
    };
    let isConfirmPassword = value => {
      return this.new_password === value;
    };

    let ispwdHints = () => {
      if (pwdHints[localStorage.getItem("pwdStrength")]) {
        return true;
      } else {
        return false;
      }
    };

    return {
      valid: true,
      login_name: null,
      preferred_username: null,
      old_password: null,
      new_password: null,
      pwdhint: "",
      pwdStrength: "",
      confirm_password: null,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        ispassword: [v => isTruePassword(v) || ispwdHints()],
        isconfirm: [v => isConfirmPassword(v) || "新密码与确认密码不一致"]
      }
    };
  },
  async created() {
    await this.getSysTem();
    this.getCurData();
  },
  methods: {
    getCurData() {
      let { login_name, preferred_username } = this.personInfo;
      this.login_name = login_name;
      this.preferred_username = preferred_username;
    },
    changePassword() {
      if (this.$refs.form.validate()) {
        let payload = {};
        if (this.has_password) {
          payload = {
            old_password: this.old_password,
            new_password: this.new_password,
            confirm_password: this.confirm_password,
            preferred_username: this.preferred_username
          };
        } else {
          payload = {
            new_password: this.new_password,
            confirm_password: this.confirm_password,
            preferred_username: this.preferred_username
          };
        }
        this.$http
          .post(`api/account/modify_password`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            bus.$emit("newSet");
            return `已成功修改账户 ${this.login_name ||
              this.preferred_username ||
              ""} 的密码。`;
          })
          .catch(({ code, message }) => {
            throw `密码修改失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    getSysTem() {
      return this.$http
        .get(`api/system_config`)
        .delegateTo(api_request)
        .then(({ password_strength }) => {
          localStorage.setItem("pwdStrength", password_strength);
          this.pwdStrength = password_strength;
          this.pwdhint = pwdHints[password_strength];
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  }
};
</script>
