<template>
  <v-app>
    <MobileHome v-if="mobileDevice === 'mobileDevice'"></MobileHome>
    <PcHome v-if="mobileDevice === 'pcDevice'"></PcHome>
  </v-app>
</template>

<script>
import { isMobile } from "@/util/util";
import PcHome from "./component/PcHome";
import MobileHome from "./component/MobileHome";

export default {
  name: "login",
  data() {
    return {
      mobileDevice: null
    };
  },
  created() {
    if (isMobile()) {
      this.mobileDevice = "mobileDevice";
    } else {
      this.mobileDevice = "pcDevice";
    }
  },
  components: {
    PcHome,
    MobileHome
  }
};
</script>
