<template>
  <div>
    <v-dialog
      v-model="show"
      width="720"
      close-delay="1000"
      :persistent="true"
      @click:outside="onCancel"
      @keydown.stop.prevent="onKeydown"
      class="mt-12"
    >
      <v-card>
        <h4 class="mx-4 py-4">
          请选择密钥类型
        </h4>
        <v-card-text class="mt-5 text-left" style="height:100px">
          <div class="d-flex justify-space-between">
            <div
              style="width:49%"
              :class="[
                typeId === item.id
                  ? 'd-flex key-wrapper active'
                  : 'd-flex key-wrapper'
              ]"
              v-for="item in keyTypes"
              :key="item.id"
              @click="isSupportInnerFido ? curType(item.id) : ''"
            >
              <img
                :src="item.imgSrc"
                class="px-2"
                style="width:60px;height:60px"
              />
              <div>
                <span class="text-h6">{{ item.name }}</span>
                <div class="text-caption">{{ item.desc }}</div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            @click.stop="onCancel"
            class="ma-1"
            plain
            color="black"
          >
            {{ $t(cancelText) }}
          </v-btn>
          <v-btn
            :loading="loading"
            class="ma-1"
            depressed
            color="primary"
            @click.stop="onConfirm"
          >
            开始绑定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { delay } from "@/util/network";
import { isWindows } from "@/util/fido_util";

const windowTypes = [
  {
    id: 0,
    imgSrc: require("@/assets/fido/hello.png"),
    name: "window hello",
    desc: "Sign in using your face,fingerprint,or a PIN"
  },
  {
    id: 1,
    imgSrc: require("@/assets/fido/key.png"),
    name: "外置硬件密钥",
    desc: "These devices let you sign in without a username and password."
  }
];
const nowindowTypes = [
  {
    id: 0,
    imgSrc: require("@/assets/fido/passKey.png"),
    name: "PassKeys",
    desc: "Sign in using your face,fingerprint,or a PIN"
  },
  {
    id: 1,
    imgSrc: require("@/assets/fido/key.png"),
    name: "外置硬件密钥",
    desc: "These devices let you sign in without a username and password."
  }
];

export default {
  name: "ConfirmBoxFido",
  data() {
    return {
      callback: function() {},
      loading: false,
      show: false,
      cancelText: "",
      successText: "",
      promise_callback: null,
      typeId: 0,
      isSupportInnerFido: null,
      isWinDevice: false,
      keyTypes: []
    };
  },
  async created() {
    this.isSupportInnerFido = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    this.typeId = this.isSupportInnerFido ? 0 : 1;
    if (!this.isSupportInnerFido) this.$emit("typeEvent", 1);
    this.isWinDevice = isWindows();
    this.keyTypes = this.isWinDevice ? windowTypes : nowindowTypes;
  },
  methods: {
    curType(id) {
      this.typeId = id;
      this.$emit("typeEvent", id);
    },
    showConfirm({ callback, cancelText = "Cancel" }) {
      this.callback = callback;
      this.cancelText = cancelText;
      this.show = true;
      return new Promise((resolve, reject) => {
        this.promise_callback = { resolve, reject };
      });
    },
    onConfirm() {
      this.loading = true;
      let promise = this.callback();
      if (promise) {
        this.$snackbar
          .delegateError(delay(promise, 150))
          .then(() => {
            this.promise_callback.resolve();
          })
          .catch(reason => {
            this.promise_callback.reject(reason);
            this.curType(this.isSupportInnerFido ? 0 : 1);
          });
      } else {
        this.promise_callback.resolve();
        this.show = false;
        this.loading = false;
        this.curType(this.isSupportInnerFido ? 0 : 1);
        return;
      }
    },
    onCancel() {
      if (!this.show || this.loading) {
        return false;
      }

      this.show = false;
      this.promise_callback.reject("cancelled");
      setTimeout(() => {
        this.curType(this.isSupportInnerFido ? 0 : 1);
      }, 20);
    },
    onKeydown(event) {
      if (event.key !== "Esc" && event.key !== "Escape") {
        return;
      }

      this.onCancel();
    }
  }
};
</script>

<style lang="less" scoped>
.v-dialog {
  margin-top: -8%;
}

.key-wrapper {
  border: 1px solid #eee;
  padding: 10px;
  cursor: pointer;
  &.active {
    border: 2px solid #0078d7;
  }
}
</style>
