<template>
  <div>
    <v-card elevation="0" style="border:1px solid #EAEBEE">
      <v-card-title class="text-body-1 mx-4" style="height:70px"
        >应用访问日志</v-card-title
      >
      <v-divider></v-divider>
      <v-container class="px-4 py-2" style="min-height:700px">
        <div
          class="text-caption d-flex justify-space-between"
          style="height:40px;line-height:40px"
        >
          <v-card-text>
            <v-app-bar
              v-if="0"
              elevation="0"
              dense
              flat
              style="background: none; margin-left: -12px"
            >
              <v-select
                :items="authMethods"
                v-model="login_method"
                label="认证方式"
                placeholder="请选择认证方式"
                hide-details="auto"
                dense
                class="mr-4"
                @change="authModeSel(login_method)"
                style="width: 160px; flex: none; background: none"
              ></v-select>
              <v-text-field
                v-model="clientIp"
                :append-icon="clientIp ? 'mdi-close-circle' : null"
                @click:append="clearClientIp"
                @input="searchClientIp(clientIp)"
                label="客户端IP"
                placeholder="请选择客户端ip"
                hide-details="auto"
                class="mr-4 loginname"
                style="width: 160px; flex: none; background: none"
              ></v-text-field>
              <v-menu
                class="datepicker-menu"
                ref="menu"
                v-model="show_start_datepicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-4 mt-5 loginname"
                    style="width: 160px; flex: none; background: none"
                    v-model="start_time"
                    :append-icon="start_time ? 'mdi-close-circle' : ''"
                    @click:append="clearStartTime"
                    placeholder="开始时间"
                    label="开始时间"
                    readonly
                    rows="1"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_time"
                  :allowed-dates="allowedStartDate"
                  no-title
                  scrollable
                  :show-current="false"
                  @input="show_start_datepicker = false"
                  :day-format="date => date.split('-')[2]"
                  locale="zh-cn"
                >
                </v-date-picker>
              </v-menu>
              <v-menu
                class="datepicker-menu"
                ref="menu"
                v-model="show_end_datepicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-4 mt-5 loginname"
                    style="width: 160px; flex: none; background: none"
                    v-model="end_time"
                    :append-icon="end_time ? 'mdi-close-circle' : ''"
                    @click:append="clearEndTime"
                    placeholder="结束时间"
                    label="结束时间"
                    readonly
                    rows="1"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_time"
                  :allowed-dates="allowedEndDate"
                  no-title
                  scrollable
                  :show-current="false"
                  @input="show_end_datepicker = false"
                  :day-format="date => date.split('-')[2]"
                  locale="zh-cn"
                >
                </v-date-picker>
              </v-menu>
              <v-btn
                depressed
                color="primary"
                style="padding: 0 24px; height: 34px !important"
                @click.stop="
                  searchAccounts(
                    sourceId,
                    clientIp,
                    start_time,
                    end_time,
                    1,
                    10
                  )
                "
              >
                {{ $t("account.Search") }}
              </v-btn>
            </v-app-bar>
            <v-simple-table class="ml-0" style="min-height:580px">
              <thead>
                <tr>
                  <th class="text-left pl-4">
                    应用名称
                  </th>
                  <th class="text-left">
                    登录IP
                  </th>
                  <th class="text-left">
                    访问时间
                  </th>
                  <th class="text-center" width="11%">
                    登录结果
                  </th>
                </tr>
              </thead>
              <tbody v-if="logs.length > 0">
                <tr v-for="item in logs" :key="item.id" class="text-left pl-4">
                  <td class="pl-4">
                    {{ item.application_name }}
                  </td>
                  <td>
                    {{ item.client_ip }}
                  </td>
                  <td>
                    {{ item.created.replace(/T/, " ") }}
                  </td>
                  <td>
                    {{ item.result ? "操作成功" : "操作失败" }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="text-center">
                  <td
                    colspan="5"
                    style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
                  >
                    暂无数据
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-pagination
              class="float-right"
              v-if="Math.ceil(totalPage / limit) > 1"
              v-model="curPage"
              :length="Math.ceil(totalPage / limit)"
              total-visible="7"
              @input="
                onPageChange(
                  sourceId,
                  clientIp,
                  start_time,
                  end_time,
                  curPage,
                  limit
                )
              "
            ></v-pagination>
          </v-card-text>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "BindAccount",
  props: ["externals"],
  data() {
    return {
      authMethods: [],
      login_method: null,
      show_start_datepicker: false,
      show_end_datepicker: false,
      start_time: null,
      end_time: null,
      clientIp: null,
      sourceId: null,
      logs: [],
      curPage: 1,
      limit: 10,
      totalPage: 0
    };
  },
  async created() {
    // await this.fetchLoginMethods();
    await this.refreshList();
  },
  methods: {
    // 选择下拉框
    authModeSel(val) {
      let curId = this.allAuthMethods.find(item => item.name === val);
      this.sourceId = curId ? curId.id : null;
      this.searchAccounts(
        this.sourceId,
        this.clientIp,
        this.start_time,
        this.end_time,
        (this.curPage = 1),
        10
      );
    },
    // 认证方式下拉选
    fetchLoginMethods() {
      return this.$http
        .get(`api/source/list_all`)
        .delegateTo(api_request)
        .then(res => {
          let allAuthMethods = res.sources;
          this.allAuthMethods = allAuthMethods;
          this.authMethods = allAuthMethods.map(item => {
            return item.name;
          });
          this.authMethods.unshift("全部");
        })
        .catch(({ code, message }) => {
          throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    searchClientIp(clientIp) {
      let pattern = /((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/g;
      if (pattern.test(clientIp)) {
        this.searchAccounts(
          this.sourceId,
          clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      }
    },
    onPageChange(sourceId, clientIp, start_time, end_time, curPage, limit) {
      this.searchAccounts(
        sourceId,
        clientIp,
        start_time,
        end_time,
        curPage,
        limit
      );
    },
    refreshList(curPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/access_application?offset=${(curPage - 1) *
            limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.logs = res.logs;
          this.totalPage = res.total;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    // 清空账户名称
    clearLoginName() {
      this.loginName = null;
      this.selectedItem = null;
      this.curPage = 1;
      if (
        this.sourceId != null ||
        this.clientIp != null ||
        this.start_time != null ||
        this.end_time != null
      ) {
        this.searchAccounts(
          this.sourceId,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
    // 清空客户端IP
    clearClientIp() {
      this.clientIp = null;
      this.curPage = 1;
      if (
        this.sourceId != null ||
        this.start_time != null ||
        this.end_time != null
      ) {
        this.searchAccounts(
          this.sourceId,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
    // 清空开始时间
    clearStartTime() {
      this.start_time = null;
      this.curPage = 1;
      if (
        this.clientIp != null ||
        this.sourceId != null ||
        this.end_time != null
      ) {
        this.searchAccounts(
          this.sourceId,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
    // 清空结束时间
    clearEndTime() {
      this.end_time = null;
      this.curPage = 1;
      if (
        this.clientIp != null ||
        this.sourceId != null ||
        this.start_time != null
      ) {
        this.searchAccounts(
          this.sourceId,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
    refreshAllAccounts() {
      return this.$http
        .get(`api/account/list`)
        .delegateTo(api_request)
        .then(res => {
          this.allAccounts = res.accounts;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    searchAccounts(
      sourceId,
      clientIp,
      start_time,
      end_time,
      curPage = 1,
      limit = 10
    ) {
      return this.$http
        .get(
          `api/access_application?source_id=${
            sourceId ? sourceId : ""
          }&client_ip=${clientIp ? clientIp : ""}&start_time=${
            start_time ? `${start_time} 00:00:00` : ""
          }&end_time=${
            end_time ? `${end_time} 00:00:00` : ""
          }&offset=${(curPage - 1) * limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.logs = res.logs;
          this.totalPage = res.total;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    allowedStartDate(val) {
      if (this.end_time) {
        let cur = new Date(val).getTime();
        let end = new Date(this.end_time).getTime();

        return cur < end;
      }
      return true;
    },
    allowedEndDate(val) {
      if (this.start_time) {
        let cur = new Date(val).getTime();
        let end = new Date(this.start_time).getTime();
        return cur > end;
      }
      return true;
    }
  },
  watch: {
    start_time() {
      if (this.start_time) {
        this.searchAccounts(
          this.sourceId,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1)
        );
      }
    },
    end_time() {
      if (this.end_time) {
        this.searchAccounts(
          this.sourceId,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1)
        );
      }
    }
  }
};
</script>

<style></style>
